.bg-off-white {
  background-color: rgb(240, 240, 240);
}

.bg-white-light {
  background-color: rgb(255, 255, 255, 0.7);
}


.dropdown-style {
  outline: none !important;
  color: black !important;
  border: none !important;
  background-color: rgb(240, 240, 240) !important;
}
.gamemodes-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.gamemodes td {
  /* padding: 20px; */
  width: 100px;
  height: 100px;
}
.gamemodes tr {
  display: flex;
}
.gamemodes table {
  table-layout: fixed;
  width: 100%;
}
.GameModes button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #f0f0f0; /* Example color */
  color: #333; /* Example text color */
  font-size: 1em; /* Adjust as needed */
  text-align: center;
  padding: 10px; /* Adjust as needed */
}

.dropdown-style:hover {
  background-color: white !important;
}

.dropdown-style:focus {
  background-color: rgb(240, 240, 240) !important;
}

.non-selectable-text {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.bg-grey {
  background-color: grey;
}

.bg-light-grey {
  background-color: rgb(180, 180, 180);
}

.pr {
  padding-right: 4px;
}

.pl {
  padding-left: 4px;
}

@keyframes shaking {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

.shake {
  animation: shaking 0.5s ease-in-out;
}

@keyframes loading {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-15px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(15px);
  }
}

.load {
  animation: loading 3s ease-in-out 0s infinite forwards;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-container svg:not(.load) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 10px;
}

.inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.button {
  background-color: rgb(240, 240, 240);
  border: none;
}

.button:hover {
  background-color: white;
  border: 1px solid black;
  border-radius: 5%;
}

.base-timer {
  position: relative;
  height: 80px;
  width: 80px;
}

.base-timer-circle {
  fill: none;
  stroke: none;
}

.base-timer-path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.base-timer-path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
}

.base-timer-svg {
  transform: scaleX(-1);
}

.text {
  font-size: 17px;
}

@media screen and (max-width: 680px) {
  html,
  .text {
    font-size: 12px;
  }
}
